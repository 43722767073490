import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface LimeFormContextProps {
  limeForms:
    | {
        getApi: (limeFormId?: string) => LimeFormsApi;
      }
    | undefined;
}

export const LimeFormContext = createContext<LimeFormContextProps>({
  limeForms: undefined,
});

export const useLimeForm = () => {
  const { limeForms } = useContext(LimeFormContext);
  return limeForms;
};

export function LimeFormsContext({
  children,
}: Readonly<{ children: ReactNode }>) {
  const [limeForms, setLimeForms] = useState<
    | {
        getApi: (limeFormId?: string) => LimeFormsApi;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://hlrkonsultensverige.lime-forms.se/js/ce/latest';
    script.onload = () => {
      setLimeForms(window.limeForms);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const value = useMemo(() => {
    return {
      limeForms,
    };
  }, [limeForms]);

  return (
    <LimeFormContext.Provider value={value}>
      {children}
    </LimeFormContext.Provider>
  );
}
